
import { importSync as i, macroCondition, getGlobalConfig } from '@embroider/macros';
let w = window;
let d = w.define;


i("./-embroider-implicit-modules.js");

d("package-majors/app", function(){ return i("package-majors/app.ts");});
d("package-majors/config/environment", function(){ return i("package-majors/config/environment.js");});
d("package-majors/router", function(){ return i("package-majors/router.ts");});
d("package-majors/types", function(){ return i("package-majors/types.ts");});
d("package-majors/utils", function(){ return i("package-majors/utils.ts");});
d("package-majors/services/ember-primitives/setup", function(){ return i("package-majors/services/ember-primitives/setup.js");});
d("package-majors/services/page-title", function(){ return i("package-majors/services/page-title.js");});
d("package-majors/component-managers/glimmer", function(){ return i("package-majors/component-managers/glimmer.js");});
d("package-majors/container-debug-adapter", function(){ return i("package-majors/container-debug-adapter.js");});
d("package-majors/services/-ensure-registered", function(){ return i("package-majors/services/-ensure-registered.js");});
d("package-majors/routes/index", function(){ return i("package-majors/routes/index.ts");});
d("package-majors/templates/query", function(){ return i("package-majors/templates/query.ts");});
d("package-majors/routes/query", function(){ return i("package-majors/routes/query.ts");});
d("package-majors/templates/application", function(){ return i("package-majors/templates/application.ts");});





if (!runningTests) {
  i("../app").default.create({});
}

