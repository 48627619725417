import { template } from "@ember/template-compiler";
import { assert } from '@ember/debug';
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import type { TOC } from '@ember/component/template-only';
type FormDataEntryValue = NonNullable<ReturnType<FormData['get']>>;
type Data = {
    [key: string]: FormDataEntryValue;
};
const handleInput = (onChange1: (data: Data, eventType: 'input' | 'submit', event: Event) => void, event1: Event | SubmitEvent, eventType1: 'input' | 'submit' = 'input')=>{
    assert('An unexpected event was passed to handleInput in <Form>', 'currentTarget' in event1 && event1.currentTarget instanceof HTMLFormElement);
    let formData1 = new FormData(event1.currentTarget);
    let data1 = Object.fromEntries(formData1.entries());
    onChange1(data1, eventType1, event1);
};
const handleSubmit = (onChange1: (data: Data, eventType: 'input' | 'submit', event: Event | SubmitEvent) => void, event1: SubmitEvent)=>{
    event1.preventDefault();
    handleInput(onChange1, event1, 'submit');
};
export interface Signature {
    Element: HTMLFormElement;
    Args: {
        /**
     *  Any time the value of any field is changed this function will be called.
     */ onChange: (/**
       * The data from the form as an Object of `{ [field name] => value }` pairs.
       * This is generated from the native [FormData](https://developer.mozilla.org/en-US/docs/Web/API/FormData)
       *
       * Additional fields/inputs/controls can be added to this data by specifying a
       * "name" attribute.
       */ data: Data, /**
       * Indicates whether the `onChange` function was called from the `input` or `submit` event handlers.
       */ eventType: 'input' | 'submit', /**
       * The raw event, if needed.
       */ event: Event | SubmitEvent) => void;
    };
    Blocks: {
        /**
     * The main content for the form. This is where inputs / fields / controls would go.
     * Within the `<form>` content, `<button type="submit">` will submit the form, which
     * triggers the `@onChange` event.
     */ default: [];
    };
}
export const Form: TOC<Signature> = template(`
  <form
    {{on "input" (fn handleInput @onChange)}}
    {{on "submit" (fn handleSubmit @onChange)}}
    ...attributes
  >
    {{yield}}
  </form>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default Form;
